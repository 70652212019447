import React, { useState } from 'react'
import zueinanderklein from '../zueinanderklein.png'
import vordergross from '../vordergross.png'
import spielbeispiel from '../spielbeispiel.png'
import { useHistory } from 'react-router-dom'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

export default function Contact() {
  const history = useHistory()
  function handleClick() {
    history.push('/impressum')
  }

  const [auf, setAuf] = useState(false)

  return (
    <div>
      <p className="centering padit fbold centerios margin-top schrift-sehrgross">
        Ein Krimispiel - Individuell gestaltet mit Ihrem Firmenlogo.
      </p>
      <div className="margin-top centering centerios">
        <img src={zueinanderklein} alt="Spielverpackung mit Ihrem Logo" />
      </div>
      <div className="padit-rl centerios schrift-sehrklein">
        <span>
          Gestaltungsvorschlag - Vorderseite und Rückseite mit Spielanleitung
        </span>
      </div>
      <div className="g-back schrift-klein">
        <p className="centering padit margin-top">
          <span className="fbold">Was ist ein Krimispiel?</span> Sie übernehmen
          im Team von bis zu 7 Spielern oder allein die Ermittlungsarbeit in
          einem spannenden Kriminalfall.
        </p>
        <p className="centering padit">
          Unser Krimispiel "Tod in Tillstetten" enthält über 25 Hinweise und
          Beweisstücke. <br />
          Kriminalistisches Gespür und Kreativität sind gefragt, um dem Täter
          oder der Täterin auf die Spur zu kommen. Je nach Erfahrung der
          Ermittler ist die Spieldauer 1 - 2,5 Stunden. Für einen Teil der
          Recherchen und Lösungshinweise wird ein Internetzugang benötigt.
        </p>
        <div className="margin-top centering centerios">
          <img src={spielbeispiel} alt="Abbildung einige Hinweise" />
        </div>
      </div>

      <p className="centering padit margin-top schrift-grau">
        Beschenken Sie zum Ende des Jahres Ihre Mitarbeiter/innen und Kunden.
        Mit einem Krimispiel in einer{' '}
        <span className="fbold">
          für Ihr Unternehmen individuell bedruckten Verpackung mit Ihrem
          Firmenlogo
        </span>
        .
      </p>
      <p className="centering padit margin-top schrift-grau schrift-etwklein">
        Ob als <span className="fbold">Weihnachtsgeschenk</span> für Mitarbeiter
        und Mitarbeiterinnen, oder als
        <span className="fbold"> Dankeschön zum Jahresende</span> und Silvester
        für Ihre Kunden: Sie schaffen ein spannendes, positives Gruppenerlebnis
        und einen bleibenden Eindruck mit Ihrem Logo auf der Verpackung.
      </p>
      <p className="centering padit schrift-grau schrift-klein">
        Auf Wunsch können Sie die Gestaltung der Verpackung auch komplett
        individualisieren und nach Ihren Wünschen gestalten.
      </p>
      <div className="centerios margin-top schrift-gross">
        <div className="light-back">
          <p className="centering padit fbold">
            Kooperativer Spielspaß für 1-7 Spieler
          </p>
        </div>
        <div className="g-back">
          <p className="centering padit fbold">
            Perfektes Dankeschön zum Jahresende{' '}
          </p>
        </div>
        <div className="y-back">
          <p className="centering padit fbold">Plastikfrei</p>
        </div>
      </div>
      <div className="margin-top centering padit centerios">
        <img src={vordergross} alt="Gestaltungsvorschlag Vorderseite" />
      </div>
      {auf ? (
        <div>
          <div className="padit centering pointer" onClick={() => setAuf(!auf)}>
            <KeyboardArrowDownIcon fontSize="inherit" color="disabled" />
            <span className="fbold">Preise</span>
          </div>
          <div className="kursiv centering padit schrift-klein">
            Alle Preise sind Nettopreise zuzüglich Umsatzsteuer und sind nur für
            gewerbliche Kunden.
            <br />
            Preise teilen wir Ihnen gerne auf Anfrage mit.
            <br />
          </div>
          <div className="y-back padit">
            <p className="centering">Aktion:</p>
            <p className="centering schrift-klein">
              Wenn Sie bis spätestens zum{' '}
              <span className="fbold">25.10.2021</span> bestellen:
            </p>

            <p className="centering margin-top">
              Preis regulär: Bei Bestellung von 190 Stück 6,79 Euro pro Stück
            </p>
            <p className="centering margin-top">
              Rabattaktion: Bei Bestellung von 190 Stück 5,22 Euro pro Stück
            </p>
            <p className="fbold centering margin-top">Sie sparen über 30%!</p>
            <p className="centering margin-top schrift-etwklein">
              Lieferung bis spätestens 10.12.2021
              <br />
              Verfügbarkeit des Angebots abhängig von unseren Kapazitäten.
            </p>
          </div>
        </div>
      ) : (
        <div className="padit centering pointer" onClick={() => setAuf(!auf)}>
          <ArrowForwardIosIcon fontSize="inherit" color="disabled" />{' '}
          <span className="fbold">Preise</span>
        </div>
      )}
      <p className="centering padit margin-top centerios">Interessiert?</p>
      <p className="fbold centerios">Wir freuen uns über Ihre Anfrage:</p>

      <p className="fbold centering schrift-etwklein centerios margin-unten">
        <span>Telefon: 0621 976 88 197</span> <br />
        <span>E-Mail: info[at]manachlux.com</span>
      </p>

      <hr />
      <p onClick={handleClick} className="impress centering padit centerios schrift-klein">
        Impressum
      </p>
    </div>
  )
}