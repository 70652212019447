import React from 'react'
import handyscreen from '../handyscreen.png'

export default function Start() {
  return (
    <div className="centering centerios">
      <h3>Wir entwickeln und betreiben Apps für die Gastronomie:</h3> <p>Web</p>
      <p>Android</p>
      <p>iOS</p>
      <div>
        <img className="start-img" src={handyscreen} alt="Handyscreen" />
      </div>
    </div>
  )
}
