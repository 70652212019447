import React from "react";
import qrcode from "../qrcode.png";

export default function Friendly() {
  return (
    <div className="centering">
      <p>Friendly Maze ist eine Anwendung für die Gastronomie.</p>
      <p>
        {" "}
        Ihre Gäste können mit Friendly Maze über den{" "}
        <strong>Scan eines QR-Codes am Tisch</strong> die Speise- und
        Getränkekarte mit dem Smartphone aufrufen.{" "}
      </p>
      <div className="flex-thing">
        <img className="qr-image" src={qrcode} alt="QR Code" />
        <p className="kursiv">
          Scannen Sie den QR-Code mit Ihrem Smartphone um sich ein Beispiel (
          <a
            href="https://friendlymaze.com/test"
            target="friendlymaze.com/test"
            rel="noopener noreferrer"
          >
            friendlymaze.com/test
          </a>
          ) anzusehen.
        </p>{" "}
      </div>
      <p>
        <div>
          Optional kann für die Karte, oder Teile der Karte, die Bestellfunktion
          freigeschaltet werden. So können Gäste mit dem Smartphone direkt an
          den Tisch bestellen. Ob und welche Bereiche der Karte für eine
          Bestellung freigegeben sind,{" "}
          <strong>kann jederzeit flexibel geändert werden.</strong>{" "}
        </div>
      </p>
      <p>
        Bei Friendly Maze steht die{" "}
        <strong>blitzschnelle und unkomplizierte Bedienung </strong>
        für Sie als Gastronom, ebenso wie die einfache Handhabung für Ihre Gäste
        an erster Stelle.
      </p>
      <p>Probieren Sie es aus!</p>
    </div>
  );
}
