import React, { Fragment } from 'react'
import Firmen from '../components/Firmen'

export default function Individuell() {
  return (
    <Fragment>
      <div>
        <Firmen />
      </div>
    </Fragment>
  )
}