import React from "react";
import contactp from "../contactp.png";
import { useHistory } from "react-router-dom";

export default function Contact() {
  const history = useHistory();
  function handleClick() {
    history.push("/impressum");
  }

  return (
    <div className="centering">
      <p>Für Anfragen und Auskünfte stehen Wir Ihnen gerne zur Verfügung!</p>
      <hr />
      <div className="margin-top">
        <img src={contactp} alt="Contact Info" />
      </div>
      <hr />
      <p onClick={handleClick} className="impress">
        Impressum
      </p>
    </div>
  );
}
