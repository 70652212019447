import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import Main from './pages/main'
import Impressum from './pages/impressum'
import Individuell from './pages/individuell'
import GoogleUmleitung from './pages/googleumleitung'

function App() {
  return (
    <>
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/impressum" component={Impressum} />
            <Route exact path="/krimispiel" component={Individuell} />
            <Route exact path="/en/home" component={GoogleUmleitung} />
            <Route exact path="/de/home" component={GoogleUmleitung} />
            <Route exact path="/home" component={GoogleUmleitung} />
          </Switch>
        </Router>
      </div>
    </>
  )
}

export default App
