import React, { Fragment } from 'react'
import { Redirect } from 'react-router'

export default function GoogleUmleitung() {
  return (
    <Fragment>
      <Redirect to={`/`} />
    </Fragment>
  )
}
